const QuotesArray = [
    "I exist, therefore be grateful - Kira", 
    "My text says lmao but my heart says kill me now please - Tina",
    "I do not dream of labour - Kira",
    "I will ignore this call out and go on with my day - Tina",
    "As in my life my best is always very mediocre - Fer",
    "it was not bad in the moment tho lmao - Tina (as for getting Lost)",
    "Find the America - Kira",
    "Damnit Tina - Kira",
    "questinos - Tobi",
    "Putain de la merde - Kira",
    "Don't be a Geralt, drink more water - Kira",
    "I have a long history of being a tyrant - Kira",
    "Germany is basically part of Eastern Europe, right - Guido to Kira",
    "International trade can suck my dick - Kira",
    "It's like period for the phone - Gues Who",
    "Thicc Boi - Tobi & Kira",
    "I am the bane of my own existence - Tobi",
    "Tobi knows where you live but Tina will get there before the cops - Tina",
    "The universe has a finance bro for everyone they're like cockroaches - Tina",
    "What are your thoughts on gay pirates Francisco? Francisco: Well as long as they're good parents it's good right?",
    "Tobi: How does Cyanide lead to severe poisoning? Ah it kills you - Aaron",
    "Aaron: Wario, Kira: Thats a lot of soju, Tina: I can only count in kimchi sorry",
    "I shall dedicate my second sandwich to your success! - Piotr",
    "Cause let's be honest the British are my hereditary enemy, but. The aesthetic is crisp - Tina",
    "what aesthetic, fake tan and thick brows? - Mikele",
    "don't wine, drink water - Kira",
    "the baguette was really magique, I'm not feeling nauseous anymore - Kira",
    "Kira: I don't want you to get gassed, Tina:That's not very German of you",
    "La vie en VF, c'est dur - Tina",
    " Kira: yeah i dont have a oven here, Tina: cheese sliver it is then",
    "I love that my standards are so low - Mikele",
]
module.exports = QuotesArray;